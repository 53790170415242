<!-- 信息中心-通知管理-逾期通知 -->
<template>
    <div class="exam-setting-wrap common-form">
        <div class="push_content">
            <div class="common-form-layout">
                <div class="common-form-wrapper content-bg">
                    <div v-hasPermi="['interconnection:noticeConfig:select']" class="common-form-main common_content">
                        <div class="push-setting-header">
                            <div class="header_content">
                                <div
                                    class="push_title"
                                    style="margin-right: 20px"
                                >
                                    逾期推送
                                </div>
                                <el-switch
                                    style="margin-top: 3px"
                                    v-model="pushSwitchAll"
                                    active-color="#3C7FFF"
                                    inactive-color="#E1E4E6"
                                    @change="changePush"
                                >
                                </el-switch>
                            </div>
                            <div class="header_tips">
                                开启状态，按照设置的推送时间进行提醒，关闭状态则不再提醒
                            </div>
                        </div>
                        <div
                            class="form_view"
                            style="text-align: left"
                            v-if="this.isShowTable==='1'"
                        >
                            <div class="push_title">推送配置</div>
                            <div class="header_tips">
                                选中即代表对应人员会通过对应途径收到提醒消息
                            </div>

                            <div class="push_table">
                                <table-data
                                    v-loading="loadingTable"
                                    :config="table_config"
                                    :tableData="tableData"
                                    :borderRadius="`10px`"
                                    ref="table"
                                >
                                    <template #salary1="{data}">
                                        <div class="name-header-slot">
                                            <span style="margin-right: 10px">
                                                <el-checkbox
                                                    :indeterminate="
                                                        isIndeterminatePush
                                                    "
                                                    v-model="checkedAll"
                                                    @change="handlePushAll($event)"
                                                ></el-checkbox>
                                            </span>
                                            <span>站内信</span>
                                        </div>
                                    </template>
                                    <template #masterpushSwitch="{data}">
                                        <span
                                            >
                                            <el-checkbox
                                                :disabled="
                                                    data.id ==
                                                        'masterTeacher' ||
                                                    data.id == 'parentUser'
                                                "
                                                v-model="data.messageSwitch"
                                                @change="handlePushChecked(data)"
                                            ></el-checkbox>
                                        </span>
                                    </template>
                                    <template #salary2="{data}">
                                        <div class="name-header-slot">
                                            <span style="margin-right: 10px">
                                                <el-checkbox
                                                    :indeterminate="
                                                        isIndeterminateMessage
                                                    "
                                                    v-model="checkedAll2"
                                                    @change="handleMessageAll($event)"
                                                ></el-checkbox>
                                            </span>
                                            <span>公众号</span>
                                        </div>
                                    </template>
                                    <template #teacherpushSwitch="{data}">
                                        <span>
                                                <el-checkbox
                                                     v-model="data.pushSwitch"
                                                    @change="handleMessageChecked(data)"
                                                ></el-checkbox>
                                        </span>

                                    </template>
                                </table-data>
                            </div>
                            <div class="push_disfelx">
                                <div class="push_title mr16">推送时间</div>
                                <el-button
                                    type="primary"
                                    @click="handleAdd"
                                    >添加</el-button
                                >
                            </div>
                            <div class="box-card">
                                <div
                                    class="push_each"
                                    v-for="(item, index) in pushArr"
                                    :key="index"
                                >
                                    <div class="each_setting">
                                        <span>请假结束时间后</span>
                                        <div class="setting_time">
                                            <el-input
                                                class="txt-score"
                                                v-model.trim="
                                                    item.timingInterval
                                                "
                                                @input="limitIptNumber(item)"
                                                placeholder="请输入"
                                                clearable
                                            ></el-input>
                                        </div>
                                        <span>小时，进行推送提醒</span>
                                        <el-button
                                            type="text"
                                            class="delet_class"
                                            @click="handleDelete(index)"
                                            >删除</el-button
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else></div>
                    </div>
                </div>
                <form-submit-btn>
                    <div slot="formSubmitBtn">
                        <el-button v-hasPermi="['interconnection:noticeConfig:update']" type="primary" @click="saveSetConfiguration">保存</el-button>
                    </div>
                </form-submit-btn>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import {debounce} from "common-local";
import FormSubmitBtn from "./Sub/FormSubmitBtn/index";
import TableData from "./Sub/TableData";
export default {
    name: "NotificationManagerScrollWrapper",
    components: { TableData, FormSubmitBtn},
    data() {
        return {
            isShowTable:'0',
            schoolId: "",
            pushSwitchAll: false,
            timingInterval: "",
            checked: true, // 站内信的勾选
            checkedAll: false, // 站内信的全选
            weChatchecked: true, // 公众号的勾选
            checkedAll2: false, // 公众号的全选
            loadingTable: true,
            isIndeterminatePush: true,
            isIndeterminateMessage: true,
            total: 0,
            tableData: [],
            tableListSel:[],  // 勾选的数据
            peopleSwitchArr: [], // 职务推送勾选
            table_config: {
                thead: [
                    {
                        label: "推送人 / 途径",
                        prop: "name",
                        align: "left",
                    },
                    {
                        label: "站内信",
                        type: "setHeader",
                        type1: "selection",
                        slotName: "salary1",
                        slotName1: "masterpushSwitch",
                        align: "left",
                        fixed: false,
                    },
                    {
                        label: "公众号",
                        type: "setHeader",
                        slotName: "salary2",
                        slotName1: "teacherpushSwitch",
                        align: "left",
                        fixed: false,
                    },
                ],
                height: "",
                check: false,
                border: true,
            },
            // 推送设置
            pushArr: [],
            selectDataPush: [], // 站内信选择后的数据
            selectDataMessage: [], // 公众号选择后的数据
            getFormObj:{},  // 获取推送配置的data
            pushConfigArr:[],  // [获取推送配置的id:{推送值}]
        };
    },
    created() {
        this.schoolId = this.$store.state.schoolId;
        this.init();
    },
    methods: {
        init(){
            this.getConfiguration();
            this.getPeopleList();
        },
        changePush(e){
            console.log('e',e);
            if(e){
                this.isShowTable = '1'
                console.log('this.isShowTable',this.isShowTable);
            }else{
                this.isShowTable = '0'
                console.log('this.isShowTable',this.isShowTable);
            }
        },
        /**
         * @Description: 获取职务名称列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-08-09 17:16:21
         */
        getPeopleList() {
            this._fet("/school/schoolDictValue/listByCondition", {
                dictKey: "teachingPost",
                schoolId: this.schoolId,
            })
                .then((res) => {
                    if (res.data.code === "200") {
                        let beforeObj = {
                            name: "班主任",
                            id: "masterTeacher",
                            pushSwitch: true,
                            messageSwitch: true,
                        };
                        let afterObj = {
                            name: "家长",
                            id: "parentUser",
                            pushSwitch: true,
                            messageSwitch: true,
                        };
                        res.data.data.unshift(beforeObj, afterObj);
                        this.tableData = res.data.data;
                        // 把每个职位  是否推送  放到 tableData里面，渲染出来
                        this.tableData.forEach((i) => {
                            this.$set(i,'pushSwitch',this.peopleSwitchArr[i.id]
                                ? this.peopleSwitchArr[i.id].pushSwitch
                                : false)
                            this.$set(i,'messageSwitch',this.peopleSwitchArr[i.id]
                                ? this.peopleSwitchArr[i.id].messageSwitch
                                : false)
                            if(i.pushSwitch){
                                this.selectDataMessage.push(i)
                            }
                        });

                        if(this.selectDataMessage.length==0){
                            this.isIndeterminateMessage = false
                        }
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                    this.loadingTable = false;
                })
                .catch(() => {
                    this.loadingTable = false;
                });
        },
        /**
         * @Description: 获取推送配置
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-08-10 10:18:56
         */
        getConfiguration() {
            this._fet(
                "/school/schoolUserPreferenceDynamicConfig/getSchoolConfiguration",
                {
                    menuId: "07060001",
                    configType: "4",
                },
            )
                .then((res) => {
                    if (res.data.code === "200") {
                        this.getFormObj = res.data.data
                        this.pushSwitchAll = res.data.data.config.pushSwitch;
                        if(this.pushSwitchAll){
                            this.isShowTable = '1'
                        }
                        this.pushArr = res.data.data.config.timingConfig;
                        this.peopleSwitchArr = res.data.data.config.pushConfig;

                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                    this.loadingTable = false;
                })
                .catch(() => {
                    this.loadingTable = false;
                });
        },
        getConfiguration1() {
            this._fet(
                "/school/schoolUserPreferenceDynamicConfig/getSchoolConfiguration",
                {
                    menuId: "07060001",
                    configType: "4",
                },
            )
                .then((res) => {
                    if (res.data.code === "200") {
                        this.getFormObj = res.data.data
                        // this.pushSwitchAll = res.data.data.config.pushSwitch;
                        // this.pushArr = res.data.data.config.timingConfig;
                        this.peopleSwitchArr = res.data.data.config.pushConfig;

                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                    this.loadingTable = false;
                })
                .catch(() => {
                    this.loadingTable = false;
                });
        },
        /**
         * @Description: 点击添加
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-08-10 15:49:14
         */
        handleAdd: debounce(function () {
            this.pushArr.push({
                sort:"",
                timingInterval: "",
            });
        },200),
        /**
         * @Description: 删除
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-08-10 15:49:59
         */
        handleDelete: debounce(function (param) {
            const index = param;
            this.pushArr.splice(index, 1);
        },200),
        /**
         * @Description: 限制输入数值
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-08-10 16:28:48
         */
        // 限制输入带一位小数
        limitIptNumber(it) {
            let str = it.timingInterval;
            str = str.replace(/[^\d.]/g, ""); // 只能输入.和数字
            str = str.replace(/^\./g, ""); //第一个字符不能是.
            str = str.replace(/\.{2,}/g, "."); // 不能连续输入.
            str = str.replace(/(\.\d+)\./g, "$1"); // .后面不能再输入.
            str = str.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
            str = str.replace(/(\d{2})\d*/, "$1"); // 最多保留5位整数
            str = str.replace(/(\.\d{1})\d*/, "$1"); // 最多保留1位小数
            // let num = parseFloat(str)
            // this.$message.warning("请输入大于0的数字");
            it.timingInterval = str;
        },
        /**
         * @Description: 站内信的全选
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-08-10 16:19:32
         */
        handlePushAll(val) {
            console.log('站内信的全选',val);
            this.checkedAll = val
            this.selectDataPush = []
            this.tableData.forEach(item=>{
                if(this.checkedAll){
                    item.messageSwitch = true
                    this.selectDataPush.push(item)
                    this.isIndeterminatePush = false;
                }else{
                    if(item.id !== 'masterTeacher' && item.id !== 'parentUser'){
                        item.messageSwitch = false
                        this.selectDataPush.push(item)
                        this.isIndeterminatePush = true;
                    }
                }
            })
            if(this.selectDataPush.length<this.tableData.length){
                let beforeObj1 = {
                        name: "班主任",
                        id: "masterTeacher",
                        pushSwitch: true,
                        messageSwitch: true,
                    };
                    let afterObj1 = {
                        name: "家长",
                        id: "parentUser",
                        pushSwitch: true,
                        messageSwitch: true,
                    };
                    this.selectDataPush.unshift(beforeObj1,afterObj1)
            }
            console.log('this.selectDataPush',this.selectDataPush);
        },
        /**
         * @Description: 站内信的单选
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-08-10 16:19:32
         */
        handlePushChecked(rows) {
            console.log('rowsrowsrowsrowsrowsrowsrows',rows.messageSwitch);

            this.getConfiguration1();
            this.selectDataPush = []
            this.tableData.forEach(item=>{
                if(item.messageSwitch){
                    this.selectDataPush.push(item)
                }
            })

            this.checkedAll = this.selectDataPush.length === this.tableData.length;
            this.isIndeterminatePush = this.selectDataPush.length > 0 && this.selectDataPush.length < this.tableData.length;
        },
        /**
         * @Description: 公众号的全选
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-08-10 16:20:03
         */
        handleMessageAll(val) {
            console.log('公众号的全选的value',val);
            this.checkedAll2 = val
            this.selectDataMessage = []
            this.tableData.forEach(item=>{
                if(this.checkedAll2){
                    item.pushSwitch = true
                    this.selectDataMessage.push(item)
                }else{
                    item.pushSwitch = false
                    this.selectDataMessage.push(item)
                }
            })
            this.isIndeterminateMessage = false;
        },
        /**
         * @Description: 公众号的单选
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-08-10 16:20:03
         */
        handleMessageChecked(value) {
            console.log('valuevaluevaluevaluevaluevaluevalue',value.pushSwitch);
            this.getConfiguration1();
            this.selectDataMessage = []
            this.tableData.forEach(item=>{
                if(item.pushSwitch){
                    this.selectDataMessage.push(item)
                    console.log('item22222222222222',item);
                }
            })
            this.checkedAll2 = this.selectDataMessage.length === this.tableData.length;
            this.isIndeterminateMessage = this.selectDataMessage.length > 0 && this.selectDataMessage.length < this.tableData.length;
        },
        /**
         * @Description: 点击保存
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-08-11 14:12:49
         */
        //
        saveSetConfiguration: debounce(function () {
            this.getFormObj.config.pushSwitch = this.pushSwitchAll
            let someArr = []
            this.pushArr.forEach((iv,index)=>{
                iv.sort = index
                someArr.push(iv.timingInterval)
            })
            console.log('someArr',someArr);
            someArr.forEach(ivv=>{
                if(ivv === ''){
                    return this.$message.warning('设置的小时数不能为空');
                }
                ivv = parseFloat(ivv)
                // if(ivv === 0){
                //     return this.$message.warning('设置的小时数应大于0');
                // }
            })
            setTimeout(()=>{
                let flag = true;   //假设不重复
                for(let i = 0;i < someArr.length - 1;i++){ //循环开始元素
                    for(let j = i + 1;j < someArr.length;j++){ //循环后续所有元素
                        //如果相等，则重复
                        if(someArr[i] == someArr[j]){
                            flag = false; //设置标志变量为重复
                            break;      //结束循环
                        }
                    }
                }
                //判断标志变量
                if(!flag){
                    this.$message.warning('设置了重复提醒小时数');
                }else{
                    this.getFormObj.config.timingConfig = this.pushArr
                    this.tableData.forEach((items)=>{
                        if(items.messageSwitch||items.pushSwitch){
                            this.pushConfigArr[items.id] = {
                                pushSwitch: items.pushSwitch,
                                messageSwitch: items.messageSwitch,
                            }
                        }
                    })
                    let pushConfigObj = Object.assign({},this.pushConfigArr)
                    // console.log('pushConfigObjpushConfigObjpushConfigObj',pushConfigObj);
                    this.getFormObj.config.pushConfig = pushConfigObj
                    let formObj = {}
                    formObj = this.getFormObj
                    console.log('formObjformObjformObj',formObj);

                    this._fet(
                        "/school/schoolUserPreferenceDynamicConfig/saveSchoolConfiguration",
                        formObj
                    )
                        .then((res) => {
                            if (res.data.code === "200") {
                                this.$message.success('保存成功');
                                this.getConfiguration();
                            } else if (res.data && res.data.msg) {
                                this.$message.error(res.data.msg);
                            }
                        })
                        .catch(() => {
                        });

                }
            },1000)
        },200),
    },
};
</script>

<style lang="scss" scoped>
.common-form-wrapper {
    margin-top: 0;
    padding-top: 10px;
}
.common_content {
    height: calc(100vh - 224px);
    overflow: hidden;
    border-radius: 8px 8px 0 0 !important;
    padding: 0 0 10px 0 !important;
}
.push-setting-header {
    text-align: left;
    padding: 29px 40px 9px;
    .header_content {
        display: flex;
    }
}
.header_tips {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #808487;
}
.push_title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #2b2f33;
    margin-bottom: 12px;
}
.form_view {
    padding: 20px;
    // height: 580px;
    height: calc(100vh - 335px);
    overflow-y: auto;
    background-color: #f7f7f7;
    display: flex;
    flex-direction: column;
    margin: 0 20px 20px;
    border-radius: 8px;

    .push_table {
        margin-top: 20px;
        margin-bottom: 50px;
    }
    .push_disfelx {
        display: flex;
        .mr16 {
            margin-right: 16px;
            margin-top: 3px;
        }
    }
    .box-card {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .push_each {
        display: flex;
        justify-content: space-between;
        .each_setting {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 420px;
            height: 56px;
            background: #ffffff;
            border: 1px solid #ebedf0;
            border-radius: 4px;
            font-family: Microsoft YaHei;
            font-size: 14px;
            font-weight: 400;
            color: #2b2f33;
            margin-bottom: 16px;
        }
        .setting_time {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #2b2f33;
            margin-top: 5px;
        }
        .txt-score {
            width: 80px;
            height: 36px !important;
            border: none;
            padding: 2px;
        }
        .delet_class {
            color: #e63a2e;
            font-family: Microsoft YaHei;
            font-size: 14px;
            font-weight: 400;
            margin-left: 10px;
        }
    }
}
.name-header-slot {
    display: flex;
    align-items: center;
}
::v-deep .el-input--suffix .el-input__inner {
    padding-right: 20px;
}
::v-deep .table-data {
    padding-bottom: 0;
}
::v-deep .el-table__cell{
    background-color: #ffffff !important;
}
::v-deep .el-table th.el-table__cell.is-leaf, .el-table td.el-table__cell{
    background-color: #F7F7F7 !important;
    border-bottom: 1px solid #EBEEF5 !important;
}
::v-deep .el-table {
    .el-table__cell {
        border-bottom: 1px solid #EBEEF5 !important;
    }
}

</style>
